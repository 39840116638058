<template>
  <div :class="['video-modal', { visiblity: !visible }]" id="video-modal">
    <div
      class="big_box"
      @mouseover="handleControls($event, 'start')"
      @mouseleave="handleControls($event, 'end')"
    >
      <video
        :src="src"
        ref="videoDom"
        class="video-box"
        crossOrigin="anonymous"
        @click.stop="togglePlay"
      ></video>
      <img
        src="https://mktv-in-cdn.mockuai.com/16239828486209487.png"
        alt=""
        class="close-icon"
        @click.stop="close"
      />

      <div
        class="controls fade-enter-active"
        v-show="!videoState.hideControl || !videoState.play"
      >
        <!-- 控制区域背景 -->
        <div class="custom-video_control">
          <!--播放或者暂停按钮-->
          <span
            v-if="videoState.play"
            class="custom-video_play custom-video_play-pause el-icon-video-pause "
            @click.stop="pause('btn')"
          >
          </span>
          <span
            v-else
            class="custom-video_play custom-video_play-play el-icon-video-play "
            @click.stop="play('btn')"
          >
          </span>
          <!-- 视频进度条 -->
          <div
            class="custom-video_control-bg"
            @mousedown="handlePrograssDown"
            @mousemove="handlePrograssMove"
            @mouseup="handlePrograssUp"
          >
            <div
              class="custom-video_control-bg-outside"
              ref="custom-video_control-bg-outside"
            >
              <span
                class="custom-video_control-bg-inside"
                ref="custom-video_control-bg-inside"
              ></span>
              <span
                class="custom-video_control-bg-inside-point"
                ref="custom-video_control-bg-inside-point"
              ></span>
            </div>
          </div>
          <!-- 声音 -->
          <div class="custom-video_control-voice">
            <span class="custom-video_control-voice-play ">
              <svg
                t="1633758624831"
                class="icon"
                viewBox="0 0 1024 1024"
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                p-id="3309"
                width="20"
                height="18"
              >
                <path
                  d="M509.226667 167.722667l-222.442667 184.341333c-16.704 13.866667-43.178667 23.402667-64.896 23.402667H170.730667A42.666667 42.666667 0 0 0 128 418.090667v187.434666c0 23.466667 19.178667 42.624 42.730667 42.624H221.866667c21.802667 0 48.170667 9.536 64.896 23.402667l222.421333 184.32V167.744zM259.541333 704.426667c-9.045333-7.509333-25.770667-13.589333-37.674666-13.589334H170.730667A85.418667 85.418667 0 0 1 85.333333 605.525333v-187.434666A85.333333 85.333333 0 0 1 170.730667 332.8H221.866667c11.776 0 28.629333-6.08 37.674666-13.589333L519.125333 104.106667c18.090667-14.997333 32.746667-8.170667 32.746667 15.402666v784.64c0 23.488-14.677333 30.378667-32.746667 15.402667L259.562667 704.405333z m436.117334-4.821334a21.333333 21.333333 0 1 1-26.026667-33.834666A196.608 196.608 0 0 0 746.666667 509.504c0-57.408-29.269333-112.32-77.482667-151.637333a21.333333 21.333333 0 1 1 26.965333-33.066667C753.792 371.797333 789.333333 438.485333 789.333333 509.504c0 75.370667-35.050667 144.981333-93.653333 190.08z m64 170.666667a21.333333 21.333333 0 1 1-26.026667-33.834667A414.506667 414.506667 0 0 0 896 507.029333c0-121.642667-61.696-237.354667-162.816-319.829333a21.333333 21.333333 0 1 1 26.965333-33.066667C870.698667 244.309333 938.666667 371.776 938.666667 507.029333a457.173333 457.173333 0 0 1-178.986667 363.221334z"
                  fill="#ffffff"
                  p-id="3310"
                ></path>
              </svg>
            </span>
            <div
              class="custom-video_control-voice-bg"
              ref="custom-video_control-voice-bg"
              @mousedown="handleVolPrograssDown"
              @mousemove="handleVolPrograssMove"
              @mouseup="handleVolPrograssUp"
            >
              <div
                class="custom-video_control-voice-bg-outside"
                ref="custom-video_control-voice-bg-outside"
              >
                <span
                  class="custom-video_control-voice-bg-inside"
                  ref="custom-video_control-voice-bg-inside"
                ></span>
                <span
                  class="custom-video_control-voice-bg-point"
                  ref="custom-video_control-voice-bg-point"
                ></span>
              </div>
            </div>
          </div>
          <!-- 时间 -->
          <div class="custom-video_control-time">
            <span>{{ currentTime ? currentTime : '00:00' }}</span>
            /
            <span>{{ duration ? duration : '00:00' }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    src: String,
  },
  watch: {
    deep: true,
    src(newVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.play()
        })
      }
    },
    visible(type) {
      if (type) {
        this.processWidth = this.videoProOut.clientWidth
      }
    },
  },
  data() {
    return {
      videoOption: {
        volume: 40,
      },
      videoState: {
        play: false, //播放状态
        hideControl: false, // 控制栏状态
        distance: 0, // 移动的距离
        downState: false, // 鼠标点击进度条
        playState: false,
        leftInit: 0, // 当前进度初始偏移量
        screenState: false,
      },
      voiceState: {
        // 同上
        distance: 0,
        downState: false,
        topInit: 0,
      },
      videoDom: null, // video
      videoProOut: null, // 视频总进度条
      videoPro: null, // 视频进度条
      videoPoi: null, // 视频进度点
      duration: 0, // 视频总时长
      currentTime: 0, // 视频当前播放时长
      processWidth: 0, // 视频进度条总长度
      voiceProOut: null, // 音频总进度条
      voicePro: null, // 音频进度条
      voicePoi: null, // 音频进度点
      volProcessHeight: 0,
    }
  },
  mounted() {
    this.videoDom = this.$refs['videoDom']
    this.videoProOut = this.$refs['custom-video_control-bg-outside']
    this.videoPro = this.$refs['custom-video_control-bg-inside']
    this.videoPoi = this.$refs['custom-video_control-bg-inside-point']

    this.voiceProOut = this.$refs['custom-video_control-voice-bg-outside']
    this.voicePro = this.$refs['custom-video_control-voice-bg-inside']
    this.voicePoi = this.$refs['custom-video_control-voice-bg-point']
    this.videoDom.volume = this.videoOption.volume / 100 // 设置初始化声音

    this.processWidth = this.videoProOut.clientWidth
    this.initMedaData()
  },

  methods: {
    initMedaData() {
      // 初始化video相关事件
      this.videoDom.addEventListener('click', () => {
        // 点击视频区域可以进行播放或者暂停
        if (this.videoDom.paused || this.videoDom.ended) {
          if (this.videoDom.ended) {
            // 如果视频结束，currentTime初始化为0
            this.videoDom.currentTime = 0
          }
          this.play('btn') //调用下面play的方法
        } else {
          this.pause('btn') //调用下面pause的方法
        }
      })

      this.videoDom.addEventListener('loadedmetadata', () => {
        // 获取视频总时长
        this.duration = this.timeTranslate(this.videoDom.duration)
      })
      this.videoDom.addEventListener('timeupdate', () => {
        // 监听视频播放过程中的时间
        this.currentTime = this.timeTranslate(this.videoDom.currentTime)
        const percentage =
          (100 * this.videoDom.currentTime) / this.videoDom.duration
        // 页面渲染进度
        this.videoPro.style.width = percentage + '%'
        this.videoPoi.style.left = percentage - 1 + '%'
      })
      this.videoDom.addEventListener('ended', () => {
        // 监听结束播放事件
        this.videoPro.style.width = 0
        this.videoPoi.style.left = 0
        this.currentTime = 0
        this.videoState.play = false
        this.videoState.hideControl = false
      })
      this.videoDom.addEventListener('volumechange', () => {
        const percentage = this.videoDom.volume * 100
        this.voicePro.style.height = percentage + '%'
        this.voicePoi.style.bottom = percentage + '%'
      })
    },
    timeTranslate(t) {
      // 时间转化
      let m = Math.floor(t / 60)
      m < 10 && (m = '0' + m)
      return m + ':' + ((t % 60) / 100).toFixed(2).slice(-2)
    },
    handlePrograssDown(ev) {
      // 监听点击进度条事件，方便获取初始点击的位置
      // 视频暂停
      this.videoState.downState = true //按下鼠标标志
      this.pause()
      this.videoState.leftInit = this.videoProOut.getBoundingClientRect().left
      this.videoState.distance = ev.clientX - this.videoState.leftInit
    },
    handlePrograssMove(ev) {
      // 监听移动进度条事件，同步播放相关事件
      if (!this.videoState.downState) return
      let disX = ev.clientX - this.videoState.leftInit
      if (disX > this.processWidth) {
        disX = this.processWidth
      }
      if (disX < 0) {
        disX = 0
      }
      this.videoState.distance = disX

      this.videoDom.currentTime =
        (this.videoState.distance / this.processWidth) * this.videoDom.duration
    },
    handlePrograssUp() {
      //松开鼠标，播放当前进度条视频
      this.videoState.downState = false
      // 视频播放
      this.videoDom.currentTime =
        (this.videoState.distance / this.processWidth) * this.videoDom.duration
      this.currentTime = this.timeTranslate(this.videoDom.currentTime)
      this.play()
    },
    handleVolPrograssDown(ev) {
      // 监听声音点击事件
      this.voiceState.topInit = this.voiceProOut.getBoundingClientRect().top
      this.volProcessHeight = this.voiceProOut.clientHeight
      this.voiceState.downState = true //按下鼠标标志
      this.voiceState.distance = ev.clientY - this.voiceState.topInit
      ev.stopPropagation()
    },
    handleVolPrograssMove(ev) {
      // 监听声音进度条移动事件
      if (!this.voiceState.downState) return
      let disY = this.voiceState.topInit + this.volProcessHeight - ev.clientY
      if (disY > this.volProcessHeight - 2) {
        disY = this.volProcessHeight - 2
      }
      if (disY < 0) {
        disY = 0
      }
      this.voiceState.distance = disY
      this.videoDom.volume = this.voiceState.distance / this.volProcessHeight
      this.videoOption.volume = this.videoDom.volume * 100
      ev.stopPropagation()
    },
    handleVolPrograssUp(ev) {
      // 监听声音鼠标离开事件
      this.voiceState.downState = false //按下鼠标标志
      this.videoDom.volume = this.voiceState.distance / this.volProcessHeight
      this.videoOption.volume = this.videoDom.volume * 100
      ev.stopPropagation()
    },
    handleControls(ev, flag) {
      // 监听离开或者进入视频区域隐藏或者展示控制栏
      switch (flag) {
        case 'start':
          this.videoState.hideControl = false
          break
        case 'end':
          this.videoState.hideControl = true
          break
        default:
          break
      }
    },

    play(flag) {
      if (flag) this.videoState.playState = true
      this.videoState.play = true
      this.$refs.videoDom.play()
    },
    pause(flag) {
      if (flag) this.videoState.playState = false
      this.videoState.play = false
      this.$refs.videoDom.pause()
    },
    togglePlay() {
      if (!this.$refs.videoDom.paused) {
        this.play('btn')
      } else {
        this.pause('btn')
      }
    },

    close() {
      this.pause()
      this.$emit('close')
    },
  },
}
</script>

<style lang="less" scoped>
.video-modal {
  position: fixed;
  z-index: 200;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  .big_box {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 495px;
  }
  .video-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 880px;
    height: 495px;
    cursor: pointer;
  }
  .close-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-left: 440px;
    margin-top: -246px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
  }
  .controls {
    width: 100%;
    height: 50px;
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

/* 暂停 或者 播放 */
.custom-video_play {
  margin-left: 5px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  font-size: 30px;
  color: #fff;
}

/* hover 播放按钮动画 */
.custom-video_play:hover {
  box-shadow: 0 0 10px #5a4180;
  transition: all 0.4s;
}
/* 控制栏 */
.custom-video_control {
  position: absolute;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.55);
  display: flex;
  align-items: center;
}
/* 控制栏进度条 */
.custom-video_control-bg {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}
/* 控制栏进度条 —— 总长度 */
.custom-video_control-bg-outside {
  width: 100%;
  height: 5px;
  border-radius: 2.5px;
  background-color: #aaa;
  position: relative;
  cursor: pointer;
}
/* 控制栏进度条 —— 播放长度 */
.custom-video_control-bg-inside {
  position: absolute;
  display: inline-block;
  width: 0;
  height: 100%;
  border-radius: 2.5px;
  left: 0;
  top: 0;
  background-color: #fff;
  // transition: all 0.2s;
}
/* 控制栏进度条 —— 播放点 */
.custom-video_control-bg-inside-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: -2.5px;
  left: -1%;
  transition: all 0.2s;
}
/* 控制栏 —— 声音、时间、全屏缩放 */
.custom-video_control-voice,
.custom-video_control-time {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
}
.custom-video_control-voice:hover > .custom-video_control-voice-bg {
  display: block;
}
.custom-video_control-voice-play {
  margin-top: 5px;
  z-index: 10;
}
.custom-video_control-voice-bg {
  display: none;
  position: absolute;
  width: 30px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.55);
  left: 0;
  bottom: 0px;
  border-radius: 15px;
}
.custom-video_control-voice-bg-outside {
  width: 5px;
  height: 70px;
  border-radius: 2.5px;
  background-color: #aaa;
  position: absolute;
  left: 50%;
  transform: translate3d(-50%, 10%, 0);
  cursor: pointer;
}
.custom-video_control-voice-bg-inside {
  display: inline-block;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 2.5px;
  background-color: #fff;
  height: 0;
}
.custom-video_control-voice-bg-point {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  left: -2.5px;
  bottom: -1px;
}
.custom-video_control-time {
  margin-right: 10px;
  font-size: 12px;
}

/* hover 显示 */
.custom-video_container:hover > .custom-video_play-pause {
  display: inline-block;
}
.custom-video_control-voice {
  width: 30px;
  height: 30px;
  cursor: pointer;
}
/* 控制栏隐藏动画 */
// .fade-enter-active {
//   transition: all 0.3s ease;
// }
// .fade-leave-active {
//   transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
// }
.fade-enter,
.fade-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
.visiblity {
  visibility: hidden;
}
</style>
